<template>
  <ion-button expand="full" @click="presentActionSheet">
    <ion-icon :icon="imagesOutline" />
    <ion-label> Add photos </ion-label>
  </ion-button>
</template>

<style scoped lang="scss">
ion-button {
  position: fixed;
  bottom: 0px;
  z-index: 1;
  width: calc(100vw);

  & ion-label {
    margin-left: 16px;
  }
}
</style>

<script>
import {
  IonButton,
  IonLabel,
  IonIcon,
  actionSheetController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
  imagesOutline,
  phonePortraitOutline,
  cameraOutline,
} from "ionicons/icons";
import cameraService from "@/services/camera.service.js";

export default defineComponent({
  name: "FsActionsSheet",
  components: { IonButton, IonLabel, IonIcon },
  setup() {
    return {
      imagesOutline,
    };
  },
  methods: {
    async presentActionSheet() {
      const actionSheet = await actionSheetController.create({
        header: "Add photos",
        buttons: [
          {
            text: "Take a picture",
            icon: cameraOutline,
            data: null,
            handler: () => {
              const photo = cameraService.takePhoto();
              console.log(photo);
            },
          },
          {
            text: "From files",
            icon: phonePortraitOutline,
            handler: () => {
              console.log("Open file system");
            },
          },
        ],
      });
      await actionSheet.present();
    },
  },
});
</script>
