<template>
  <!-- Menu -->
  <!-- <fs-menu /> -->

  <!-- Action sheet -->
  <fs-actions-sheet />

  <ion-page>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button auto-hide="false"></ion-menu-button>
      </ion-buttons>
      <ion-title>Photos</ion-title>
    </ion-toolbar>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Blank</ion-title>
        </ion-toolbar>
      </ion-header>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  menuController,
} from "@ionic/vue";
// import FsMenu from "@/components/layout/FsMenu.vue";
import FsActionsSheet from "@/components/layout/FsActionsSheet.vue";
import { personCircle } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    // FsMenu,
    FsActionsSheet,
  },
  setup() {
    return { personCircle };
  },
  methods: {
    openMenu() {
      menuController.enable(true, "main");
      menuController.open("main");
    },
  },
});
</script>

<style scoped>
#container {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
