import {
  Camera,
  CameraResultType,
  CameraSource,
  // Photo,
} from "@capacitor/camera";
// import { Filesystem, Directory } from '@capacitor/filesystem';
// import { Storage } from '@capacitor/storage';

async function takePhoto() {
  const photo = await Camera.getPhoto({
    resultType: CameraResultType.Uri,
    source: CameraSource.Camera,
    quality: 100,
  });

  return photo;
}

export default {
  takePhoto: takePhoto,
};
